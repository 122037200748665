import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import LandingItem from './item';
import './index.css';

const LandingOffer = ({ data }) => {
    return (
        <Container fluid className="landing-offer-outher-container" style={{marginBottom: '80px'}}>
            {data !== undefined ? (
            <>
            <Row>
                <Col><h2 className="text-align-center landing-offer-heading">{data.naslovponuda}</h2></Col>
            </Row>
            <Row>
                <Col className="landing-offer-headings" md={12} lg={6}>
                    <h4 className="text-align-center landing-offer-heading-item">Masaže</h4>
                </Col>
                <div className="offer-glow"></div>
                <Col className="d-md-none d-sm-none d-lg-block d-xs-none landing-offer-headings">
                    <h4 className="text-align-center landing-offer-heading-item">Osobni razvoj</h4>
                </Col>
            </Row>
            <Row>
                {data.masaze !== undefined ? (
                    <>
                       <LandingItem link={data.masaze[0].masaza[0].uri} sourceUrl={data.masaze[0].slika.sourceUrl} naziv={data.masaze[0].masaza[0].title}/>
                       <LandingItem link={data.masaze[1].masaza[0].uri} sourceUrl={data.masaze[1].slika.sourceUrl} naziv={data.masaze[1].masaza[0].title}/>
                       <Col className="d-md-block d-lg-none" md={12}>
                           <h4 className="landing-offer-heading-item text-align-center">Osobni razvoj</h4>
                       </Col>
                       <LandingItem link={data.osobniRazvoj[0].osobniRazvoj[0].uri} sourceUrl={data.osobniRazvoj[0].slika.sourceUrl} naziv={data.osobniRazvoj[0].osobniRazvoj[0].title}/>
                       <LandingItem link={data.osobniRazvoj[1].osobniRazvoj[0].uri} sourceUrl={data.osobniRazvoj[1].slika.sourceUrl} naziv={data.osobniRazvoj[1].osobniRazvoj[0].title}/>       
                    </>
                ) : null}
             </Row>
            </>
            ) : null}
            
        </Container>
    )
}

export default LandingOffer;