import React from 'react';
import './index.css';
import { Container, Row, Col } from 'react-bootstrap';
import BackgroundLogo from '../../images/OlanRei_logo_watermark.png';

const TextImageComponent = ({ heading, text, sourceUrl = '' }) => (
    <Container fluid>
        <Row className="text-image-container-outher-container">
            <div className="landing-background-logo-container">
                <img className="landing-background-logo" src={BackgroundLogo} alt="err"/>
            </div>
            <Col className="text-container-component" sm={12} lg={6}>
                <h2>{heading}</h2>
                <p dangerouslySetInnerHTML={{__html: text}}></p>
            </Col>
            <Col className="image-container-component" sm={12} lg={6}>
                <img className="text-img-component-image" src={sourceUrl} alt="err"/>
            </Col>
        </Row>
    </Container>
)

export default TextImageComponent;