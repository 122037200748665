import React, { useEffect, useState } from 'react';
import './App.css';
import axios from 'axios';
import { BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import Landing from './pages/landing';
import Layout from './components/layout';
import 'bootstrap/dist/css/bootstrap.css';
import Blog from './pages/blog';
import AboutMe from './pages/aboutMe';
import Contact from './pages/contant';
import { WaveSpinner } from 'react-spinners-kit';

function App() {

  const [blogData, setBlogData] = useState();
  const [aboutMeData, setAboutMeData] = useState({});
  const [offerData, setOfferData] = useState();
  const [contactData, setContactData] = useState();
  const [landingData, setLandingData] = useState()

  useEffect(() => {
    fetchingData();
  }, [])

  const fetchingData = async () => {
    let data = await axios.post('https://admin.olanrei.com/graphql', {
          query: `
          query MyQuery {
            clanci {
              nodes {
                seo {
                  metaDesc
                  title
                }
                blocks {
                  name
                  ... on CoreParagraphBlock {
                    originalContent
                    name
                    order
                  }
                  ... on CoreImageBlock {
                    attributes {
                      ... on CoreImageBlockAttributes {
                        id
                        url
                      }
                    }
                  }
                  ... on CoreHeadingBlock {
                    originalContent
                    name
                    order
                  }
                  ... on CoreButtonsBlock {
                    order
                    innerBlocks {
                      ... on CoreButtonBlock {
                        attributes {
                          ... on CoreButtonBlockAttributes {
                            url
                            text
                          }
                        }
                      }
                    }
                  }
                }
                uri
                clanak {
                  vezaniClanak {
                    veza {
                      ... on Clanak {
                        id
                        uri
                        title
                      }
                    }
                    slikavezaniclanak {
                      sourceUrl
                    }
                  }
                  baner {
                    sourceUrl
                  }
                }
                title
              }
            }
            pages {
              nodes {
                seo {
                  metaDesc
                  title
                }
                title
                baner {
                  banerAboutMe {
                    sourceUrl
                  }
                }
                certificats {
                  certifikat {
                    slika {
                      sourceUrl
                    }
                  }
                }
                blocks {
                  ... on CoreParagraphBlock {
                    originalContent
                    name
                  }
                  ... on CoreHeadingBlock {
                    name
                    originalContent
                  }
                  ... on CoreImageBlock {
                    attributes {
                      ... on CoreImageBlockAttributes {
                        url
                      }
                    }
                  }
                }
                offer {
                  naslovponuda
                  osobniRazvoj {
                    osobniRazvoj {
                      ... on Clanak {
                        id
                        uri
                        clanak {
                          baner {
                            sourceUrl
                          }
                        }
                        title
                      }
                    }
                    slika {
                      sourceUrl
                    }
                  }
                  masaze {
                    masaza {
                      ... on Clanak {
                        id
                        uri
                        clanak {
                          baner {
                            sourceUrl
                          }
                        }
                        title
                      }
                    }
                    slika {
                      sourceUrl
                    }
                  }
                }
                location {
                  adresa
                }
                contactText {
                  info
                }
                reservation {
                  text
                  brojTelefona
                }
              }
            }
          }                                     
          `
        }, {
            headers: {'Content-Type': 'application/json'}
        })
        setBlogData(data)
        setAboutMeData(data.data.data.pages.nodes[1]);
        setOfferData(data.data.data.pages.nodes[2].offer);
        setContactData(data.data.data.pages.nodes[0]);
        let landingData = await axios.post('https://admin.olanrei.com/graphql', {
            query: `
            query MyPage {
              page(id: "cG9zdDo5") {
                seo {
                  metaDesc
                  title
                }
                glavniRotator {
                  rotator {
                    poslovica {
                      naslov
                      tekst
                    }
                    slika {
                      sourceUrl
                    }
                  }
                }
                moreAboutMe {
                  naslov
                  opis
                  slika {
                    sourceUrl
                  }
                }
                offer {
                  naslovponuda
                  osobniRazvoj {
                    osobniRazvoj {
                      ... on Clanak {
                        id
                        uri
                        clanak {
                          baner {
                            sourceUrl
                          }
                        }
                        title
                      }
                    }
                    slika {
                      sourceUrl
                    }
                  }
                  masaze {
                    masaza {
                      ... on Clanak {
                        id
                        uri
                        clanak {
                          baner {
                            sourceUrl
                          }
                        }
                        title
                      }
                    }
                    slika {
                      sourceUrl
                    }
                  }
                }
                aboutOlanrei {
                  naslov1
                  opis1
                  slika1 {
                    sourceUrl
                  }
                }
              }
            }                         
            `
        }, {
            headers: {'Content-Type': 'application/json'}
        });
        setLandingData(landingData);
      }

  return (
    <div>
      <Router>
        {landingData !== undefined ? (
          <Layout>
          <Switch>
            <Route exact path="/">
              <Landing data={landingData}/>
            </Route>
            <Route path="/o-meni">
              <AboutMe data={aboutMeData} offer={offerData}/>
            </Route>
            <Route path="/kontakt-i-rezervacije">
              <Contact data={contactData}/>
            </Route>
            {blogData !== undefined ? blogData.data.data.clanci.nodes.map(val => (
              <Route path={val.uri}>
                <Blog seo={val.seo} title={val.title} blocks={val.blocks} baner={val.clanak.baner.sourceUrl} linkedBlog={val.clanak.vezaniClanak}/>
              </Route>
            )) : null}
            <Route path="/*">
              <Landing />
            </Route>
          </Switch>
          </Layout>
        ) : (
          <div className="loading-animation-container">
            <WaveSpinner size={40} color={'#E9F0D6'} />
          </div>
        )}
      </Router>
    </div>
  );
}

export default App;