import React, { useEffect, useState } from 'react';
import MainNavbar from '../navbar';
import Footer from '../footer';
import axios from 'axios';
import './index.css';
import BgImage from '../../images/Bckground.jpg';

const Layout = (props) => {

    const [navbarData, setNavbarData] = useState([]);
    const [footerData, setFooterData] = useState([]);
    const [bgHeight, setBgHeight] = useState({height: '136px'});
    const [bgClass, setBgClass] = useState('');

    useEffect(() => {
        fetchingData()
    }, [])

    const fetchingData = async () => {
        let data = await axios.post('https://admin.olanrei.com/graphql', {
            query: `
            query MyQuery {
                menu(id: "dGVybToy") {
                  id
                  menuItems {
                    nodes {
                      path
                      label
                      parentDatabaseId
                      childItems {
                        nodes {
                          label
                          path
                        }
                      }
                    }
                  }
                }
              }
                                 
            `
        }, {
            headers: {'Content-Type': 'application/json'}
        })
        setNavbarData(data.data.data.menu.menuItems.nodes)
        setFooterData(data.data.data.menu.menuItems.nodes)
    }

    return (
        <>
            <div style={bgHeight} className={`navbar-background ${bgClass}`}></div>
            <div className="backgroung-img-container">
                <img className="background-img" src={BgImage} alt="err"/>
            </div>
            <div className="main-outher-container">
                <MainNavbar data={navbarData} setBgHeight={setBgHeight} setBgClass={setBgClass}/>
                    {props.children}
            </div>
            <Footer data={footerData}/>
        </>
    )
}

export default Layout;