import React, { useEffect } from 'react';
import './index.css';
import TextAlignedCenter from '../../components/TextAlignedCenter';
import LandingAboutMe from '../../components/landingAboutMe';
import BgLogo from '../../images/OlanRei_logo_watermark.png';
import { useHistory } from 'react-router-dom';
import Seo from '../../components/seo';

const Blog = ({ title, blocks, baner, linkedBlog, uri, seo }) => {

    const history = useHistory();

    useEffect(() => {
        let links = document.querySelectorAll('.blog-text-align-center-container a');
        links.forEach(val => {
            val.addEventListener('click', e => {
                e.preventDefault();
                window.scrollTo(0, 0);
                history.push('/kontakt-i-rezervacije/');
            })
        })
        if(blocks[5].innerBlocks !== undefined){
            let buttonEvent = document.querySelector('.landing-about-button');
            buttonEvent.addEventListener('click', e => {
                e.preventDefault();
                window.scrollTo(0, 0);
                history.push('/clanak/ucinci-olan-rei-masaze/');
            })
        }
        return(
            links.forEach(val => {
                val.removeEventListener('click', e => {
                    e.preventDefault();
                    console.log('link clicked');
                });
            })
        )
    }, [blocks])

    return (
        <div>
            <Seo title={seo.title} description={seo.metaDesc} link={`https://olanrei.com${uri}`}/>
            <div className="blog-baner-container ">
                <img src={baner} alt="err"/>
                <h1 className="blog-baner-small-title">{title}</h1>
                <h1 className="blog-baner-big-title">{title}</h1>
            </div>
            <div className="blog-background-logo">
                <img src={BgLogo} alt="err"/>
            </div>
            {blocks[5].innerBlocks === undefined ? (
                <div className="blog-text-blocks">
                    <TextAlignedCenter header={blocks[0].originalContent} text={blocks[1].originalContent}/>
                    <LandingAboutMe sourceUrl={blocks[2].attributes.url} text={blocks[4].originalContent} header={blocks[3].originalContent} dangerouslySetHeader={true}/>
                    <TextAlignedCenter marginTopClass={'margin-top-blog-paragraph'} header={blocks[5].originalContent} text={blocks[6].originalContent}/>
                    {blocks.length > 7 ? blocks.map((val, i) => {
                        if(i < 7){
                            return null;
                        }else{
                            if(val.name.includes('heading')){
                                return <div style={{marginTop: '40px'}} className="blog-text-align-center-container" dangerouslySetInnerHTML={{__html: val.originalContent}}></div>
                            }else if(val.name.includes('paragraph')){
                                return<div className="blog-text-align-center-container" dangerouslySetInnerHTML={{__html: val.originalContent}}></div>
                            }else if(val.name.includes('image')){
                                return<div className="blog-aditional-image-container"><img className="blog-aditional-image" src={val.attributes.url} alt="err"/></div>
                            }
                        }
                    }): null}
                </div>
            ) : (
                <div className="blog-text-blocks">
                    <TextAlignedCenter header={blocks[0].originalContent} text={blocks[1].originalContent}/>
                    <LandingAboutMe sourceUrl={blocks[2].attributes.url} text={blocks[4].originalContent} header={blocks[3].originalContent} dangerouslySetHeader={true} button={blocks[5].innerBlocks[0].attributes}/>
                    <TextAlignedCenter marginTopClass={'margin-top-blog-paragraph'} header={blocks[6].originalContent} text={blocks[7].originalContent}/>
                    {blocks.length > 8 ? blocks.map((val, i) => {
                        if(i < 8){
                            return null;
                        }else{
                            if(val.name.includes('heading')){
                                return <div style={{marginTop: '40px'}} className="blog-text-align-center-container" dangerouslySetInnerHTML={{__html: val.originalContent}}></div>
                            }else if(val.name.includes('paragraph')){
                                return<div className="blog-text-align-center-container" dangerouslySetInnerHTML={{__html: val.originalContent}}></div>
                            }
                        }
                    }): null}
                </div>
            )}

            <div className="link-blog-container" onClick={e => {history.push(linkedBlog.veza[0].uri); window.scrollTo(0, 0)}}>
                <div className="linked-blog-description-container">
                    <p><strong>Provjerite i ovu Olan Rei uslugu</strong></p>
                    <h2>{linkedBlog.veza[0].title}</h2>
                </div>
                <div className="linked-blog-image-container">
                    <img src={linkedBlog.slikavezaniclanak.sourceUrl} alt="err"/>
                </div>
            </div>
        </div>
    )
}

export default Blog;