import React from 'react';
import { Col } from 'react-bootstrap';
import './item.css';
import { Link } from 'react-router-dom';

const LandingOffer = ({ sourceUrl, naziv, link }) => {
    return (
        <Col className="landing-offer-item" lg={3} md={6} xs={6}>
            <Link to={link} onClick={e => window.scrollTo(0, 0)}>
                <div className="landing-offer-item-container">
                    <img src={sourceUrl} alt="err"/>
                    <h3 className="landing-offer-item-title">{naziv}</h3>
                    <div className="landing-offer-item-overlay box-shadow-nav"></div>
                </div>
            </Link>
        </Col>
    )
}

export default LandingOffer;