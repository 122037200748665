import React, { useState } from 'react';
import TextAlignedCenter from '../../components/TextAlignedCenter';
import LandingAboutMe from '../../components/landingAboutMe';
import './index.css';
import { Container, Row, Col, Modal, Button } from 'react-bootstrap';
import LandingOffer from '../../components/landingOffer';
import Seo from '../../components/seo';

const AboutMe = ({ data, offer }) => {

    const [popup, setPopup] = useState({show: false, certificate: {}})

    return(
        <div>
            {data.baner !== undefined ? (
            <>
                <Seo title="O meni - Olan Rei" description={data.seo.metaDesc} link='https://olanrei.com/o-meni'/>
                <div className="blog-baner-container">
                <img src={data.baner.banerAboutMe.sourceUrl} alt="err"/>
                <h1 className="blog-baner-small-title">{data.title}</h1>
                <h1 className="blog-baner-big-title">{data.title}</h1>
                </div>
                <div className="blog-text-blocks">
                    <TextAlignedCenter header={data.blocks[0].originalContent} text={data.blocks[1].originalContent}/>
                    <LandingAboutMe sourceUrl={data.blocks[2].attributes.url} text={data.blocks[4].originalContent} header={data.blocks[3].originalContent} dangerouslySetHeader={true} aboutMe={true}/>
                </div>
                <Container className="about-me-my-certificates">
                    <Row>
                        <Col>
                            <h3 className="header margin-bottom-25">Moji certifikati</h3>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="about-me-my-certificates-item-container" md={4} lg={4} sm={4} xs={12}>
                            <div className="about-me-my-certificates-item" onClick={e => window.innerWidth > 570 ? setPopup({show: true, certificate: data.certificats.certifikat[0].slika.sourceUrl}) : null}>
                                <img src={data.certificats.certifikat[0].slika.sourceUrl} alt="err"/>
                            </div>
                        </Col>
                        <Col className="about-me-my-certificates-item-container" md={4} lg={4} sm={4} xs={12}>
                            <div className="about-me-my-certificates-item" onClick={e => window.innerWidth > 570 ? setPopup({show: true, certificate: data.certificats.certifikat[1].slika.sourceUrl}) : null}>
                                <img src={data.certificats.certifikat[1].slika.sourceUrl} alt="err"/>
                            </div>                        
                        </Col>
                        <Col className="about-me-my-certificates-item-container" md={4} lg={4} sm={4} xs={12}>
                            <div className="about-me-my-certificates-item" onClick={e => window.innerWidth > 570 ? setPopup({show: true, certificate: data.certificats.certifikat[2].slika.sourceUrl}) : null}>
                                <img src={data.certificats.certifikat[2].slika.sourceUrl} alt="err"/>
                            </div>                        
                        </Col>
                    </Row>
            </Container>
            <Modal show={popup.show} onHide={e => setPopup({show: false})}>
                <Modal.Body>
                    <img src={popup.certificate} alt="err"/>
                </Modal.Body>
                
            </Modal>
            <LandingOffer data={offer}/>
            </>
            ) : null}
            
        </div>
    )
}

export default AboutMe;