import React, { useEffect, useState } from 'react';
import './index.css';
import { Link, useHistory } from 'react-router-dom';
import logo from '../../images/olanrei-logo.png';
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import OlanreiText from '../../images/OlanRei_natpis-transp-01-header.png';

const MainNavbar = ({ data, setBgHeight, setBgClass }) => {

    const [navbarScrollClass, setNavbarScrollClass] = useState({ logo: {}, navbar: {height: '136px'}});
    const [showDropdown, setShowDropdown] = useState({1: false, 2: false});
    const history = useHistory();
    const [showNavbarDropdown, setShowNavbarDropdown] = useState(false)

    useEffect(() => {
        window.addEventListener('scroll', () => {
            if(window.pageYOffset !== 0 && window.pageYOffset > 20){
                setNavbarScrollClass({logo: {transform: 'scale(.7)'}, navbar: {height: '100px'}, navbarClass: 'set-offset-y', colapse: {top: '110px'}});
                setBgHeight({height: '100px'});
                setBgClass('box-shadow-nav')
            }else if(window.pageYOffset < 5){
                setNavbarScrollClass({logo: {transform: 'translateY(5px)'}, navbar: {height: '136px'}, navbarClass: '', colapse: {top: '138px'}});
                setBgHeight({height: '136px'});
                setBgClass('');
            }
        }, true)
        return (
            window.removeEventListener('scroll', () => {
            })
        )
    }, []);

    const redirecting = (e, url) => {
        e.preventDefault();
        window.scrollTo(0, 0);
        history.push(url);
    }

    return (
        <div>
            <Navbar collapseOnSelect expand="lg" sticky="top" style={navbarScrollClass.navbar} className={navbarScrollClass.navbarClass}>
                <Navbar.Brand onClick={e => redirecting(e, '/')} href="/"><div className="main-navbar-logo"><img style={navbarScrollClass.logo} className="navbar-logo" src={logo} alt="err"/></div></Navbar.Brand>
                <Link className="rezerviraj-termin-button d-lg-none d-md-block d-sm-block d-xs-none" to="/kontakt-i-rezervacije">Rezerviraj termin</Link>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse style={navbarScrollClass.colapse} id="basic-navbar-nav">
                    <Nav className="mr-auto">
                        {data.filter(val => val.parentDatabaseId === 0).map((val, i) => {
                            if(i === 0){
                                return <Nav.Link onClick={e => redirecting(e, val.path)} className="border-top-on-collapse" href={'#'}>{val.label}</Nav.Link>        
                            }
                            if(val.childItems.nodes.length !== 0){
                                return (<NavDropdown className="main-navbar-item" title={val.label} id="basic-nav-dropdown" onClick={e => setShowDropdown({1: false, 2: false, [i]: !showDropdown[i]})} show={showDropdown[i]} onMouseEnter={e => {
                                    let newObj = {1: false, 2: false};
                                    newObj[i] = true;
                                    setShowDropdown(newObj);
                                }} onMouseLeave={e => setShowDropdown({1: false, 2: false})}>
                                    <div className="navbar-dropdown-trigger"></div>
                                    {val.childItems.nodes.map(val => (
                                        <>
                                         <NavDropdown.Item onClick={e => redirecting(e, val.path)} className="somthing" href={val.path}>{val.label}</NavDropdown.Item>
                                        </>
                                    ))}
                                   </NavDropdown>)
                            }
                            return <Nav.Link onClick={e => redirecting(e, val.path)}  className="main-navbar-item" href={val.path}>{val.label}</Nav.Link>
                        })}
                    <Nav.Link id='md-display-none' onClick={e => redirecting(e, '/kontakt-i-rezervacije')} className="rezerviraj-termin-button rezerviraj-termin-button-dropdown d-lg-none d-md-none d-sm-none d-xs-block" href="#">Rezerviraj termin</Nav.Link>
                    </Nav>
                    <Link onClick={e => window.scrollTo(0, 0)} className="rezerviraj-termin-button rezerviraj-termin-button-dropdown d-lg-block d-md-none d-sm-none d-xs-none" to="/kontakt-i-rezervacije">Rezerviraj termin</Link>
                </Navbar.Collapse>
                <div style={navbarScrollClass.navbar} className="navbar-natpis-container">
                    <img src={OlanreiText} alt="err" className="navbar-natpis"/>
                </div>
            </Navbar>
        </div>
    )
}

export default MainNavbar;