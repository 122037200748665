import React from 'react';
import './index.css';
import { Container, Row, Col } from 'react-bootstrap';
import OlanreiNatpis from '../../images/OlanRei_natpis-transp-02-footer.png';
import FooterBackground from '../../images/Footer-bck.jpg';
import FooterIcon1 from '../../images/Ikona-01.png';
import FooterIcon2 from '../../images/Ikona-02.png';
import FooterIcon3 from '../../images/Ikona-03.png';
import FooterIcon4 from '../../images/Ikona-04.png';
import LogoInvert from '../../images/OlanRei_logo_invert.png';
import { Link } from 'react-router-dom';

const Footer = () => {
    return (
        <div className="footer-outher-container">
            <Container fluid className="footer-features-outher-container box-shadow-footer">
                <Row className="footer-features">
                    <Col className="footer-features-item" sm={6} md={3} xs={6}>
                        <img src={FooterIcon1} alt="err"/>
                        <p>Individualni termin</p>
                    </Col>
                    <Col className="footer-features-item" sm={6} md={3} xs={6}>
                        <img src={FooterIcon2} alt="err"/>
                        <p>Potpuni osjećaj privatnosti</p>
                    </Col>
                    <Col style={{margin: 0}} className="footer-features-item" sm={6} md={3} xs={6}>
                        <img src={FooterIcon3} alt="err"/>
                        <p>Bez čekanja</p>
                    </Col>
                    <Col style={{margin: 0}} className="footer-features-item" sm={6} md={3} xs={6}>
                        <img src={FooterIcon4} alt="err"/>
                        <p>Poštivanje vašeg vremena</p>
                    </Col>
                </Row>
            </Container>
            <div className="footer-shade"></div>
            <div className="main-footer">
                <div className="footer-main-image">
                    <img src={FooterBackground}/>
                </div>
                <div className="footer-info">
                    <img className="footer-logo" src={LogoInvert} alt="err"/>
                    {/* <div className="footer-info-upper-links">
                        <Link className="footer-info-border" to="/o-meni">O meni</Link>
                        <Link className="footer-info-border" to="/masaze">Masaže</Link>
                        <Link className="footer-info-border" to="/osobni-razvoj">Osobni razvoj</Link>
                        <Link className="footer-info-border" to="/kontakt-i-rezervacije">Cjenik</Link>
                        <Link to="/kontakt-i-rezervacije">Rezervacije</Link>
                    </div> */}
                    <div>
                        <Link className="footer-info-border" to="/uvjeti-koristenja">Uvjeti korištenja</Link>
                        <Link to="/kontakt-i-rezervacije" onClick={e => window.scrollTo(0, 0)}>Kontakt</Link>
                    </div>
                    <p>Olan Rei 2020.</p>
                </div>
                <img className="footer-olanrei-natpis" src={OlanreiNatpis}/>
            </div>
            
        </div>
    )
}

export default Footer;