import React from 'react';
import TextAlignedCenter from '../../components/TextAlignedCenter';
import LandingAboutMe from '../../components/landingAboutMe';
import './index.css';
import { Container, Row, Col } from 'react-bootstrap';
import LandingOffer from '../../components/landingOffer';
import PhoneIcon from '@material-ui/icons/Phone';
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from "react-google-maps";
import Seo from '../../components/seo';


const MyMapComponent = withScriptjs(withGoogleMap((props) =>
  <GoogleMap
    defaultZoom={13}
    defaultCenter={{ lat: 46.300252, lng: 16.339029 }}
  >
    {props.isMarkerShown && <Marker position={{ lat: 46.300252, lng: 16.339029 }} />}
  </GoogleMap>
))

const Contact = ({ data }) => {
    return(
        <div>
            {data !== undefined ? (
            <>
                <Seo title="Kontakt - Olan Rei" description={data.seo.metaDesc} link='https://olanrei.com/kontakt-i-rezervacije'/>
                <div className="blog-baner-container">
                    <img src={data.baner.banerAboutMe.sourceUrl} alt="err"/>
                    <h1 className="blog-baner-small-title">{data.title}</h1>
                    <h1 className="blog-baner-big-title">{data.title}</h1>
                </div>
                <div className="contact-text-container">
                    <h3 className="header">Rezervacije termina</h3>
                    <p dangerouslySetInnerHTML={{__html: data.contactText.info}}></p>
                </div>
                <div className="contact-phone-number-container"><div className="contact-phone-number box-shadow-footer"><PhoneIcon fontSize='large' /><p>{data.reservation.brojTelefona}</p></div></div>
                <div className="contact-location-container">
                    <div className="location-container" sm={12} md={6}>
                        <h3 className="header contact-contact-heading">Salon</h3>
                        <p className="contact-contact-text"><strong>{data.location.adresa}</strong></p>
                        <MyMapComponent
                            isMarkerShown
                            googleMapURL="https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=AIzaSyB6h1LA0D2FJy9Q3jwOLCDpY-0NKGQXEdU"
                            loadingElement={<div style={{ height: `100%` }} />}
                            containerElement={<div style={{ maxHeight: '1200px', height: `500px`, width: `100%`, borderRadius: '10px', overflow: 'hidden' }} />}
                            mapElement={<div style={{ height: `100%` }} />}
                        />
                    </div>
                    <div className="contact-contact-container">
                        <h3 className="header contact-contact-heading">Kontakt</h3>
                        <p className="contact-contact-text" dangerouslySetInnerHTML={{__html: data.reservation.text}}></p>
                    </div>
                </div>
            </>
            ) : null}
            
            

        </div>
    )
}

export default Contact;