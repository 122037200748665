import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './index.css';
import { Link } from 'react-router-dom';

const LandingAboutMe = ({ sourceUrl, header, text, imageOutlineClass, dangerouslySetHeader = false, button, imageContainerClass='', aboutMe }) =>{
    
    let aboutParagraph = '';
    if(aboutMe){
        aboutParagraph = '<p class="small-text">Martina Haber, terapeut</p>';
    }
    
    return (<Container fluid className="landing-more-about-me-container">
        <Row className="text-image-container-outher-container">
            <Col className={`image-container-component landing-more-about-me ${imageContainerClass}`} sm={12} lg={6}>
                <img className={`text-img-component-image ${imageOutlineClass}`} src={sourceUrl} alt="err"/>
            </Col>
            {dangerouslySetHeader ? [
                (button ? (
                    <Col className="text-container-component landing-more-about-me-img" sm={12} lg={6} dangerouslySetInnerHTML={{__html: `${header}${text}<a class="landing-about-button" href=${button.url}>${button.text}</a>`}}>
                    </Col> 
                ): (
                    <Col className="text-container-component landing-more-about-me-img" sm={12} lg={6} dangerouslySetInnerHTML={{__html: header + text + aboutParagraph}}>
                    </Col>
                )),
             ] : (
                <Col className="text-container-component landing-more-about-me-img landing-more-about-me-text" sm={12} lg={6}>
                    <h2>{header}</h2>
                    <p dangerouslySetInnerHTML={{__html: text}}></p>
                    <p style={{fontSize: "16px"}}>Martina Haber, terapeut</p>
                    <Link to="/o-meni" onClick={e => window.scrollTo(0, 0)} className="landing-about-button">Više o meni</Link>
                </Col>
            )}
        </Row>
    </Container>)
}

export default LandingAboutMe;