import React, { useEffect, useState } from 'react';
import axios from 'axios';
import MainSlider from '../../components/mainSlider';
import LandginAboutMe from '../../components/landingAboutMe';
import LandginOffer from '../../components/landingOffer';
import TextImageComponent from '../../components/textImageComponent';
import Seo from '../../components/seo';

const Landing = ({ data }) => {

    const [mainSliderData, setMainSliderData] = useState({rotator: [{slika: '', poslovica: {}}]});
    const [landingAboutMeData, setLandingAboutMeData] = useState({slika: ''});
    const [aboutOlanReiData, setAboutOlanReiData] = useState({slika1: ''});
    const [offerData, setOfferData] = useState();

    useEffect(() => {
        fetchingData();
    }, [])

    const fetchingData = async () => {
        setMainSliderData(data.data.data.page.glavniRotator);
        setAboutOlanReiData(data.data.data.page.aboutOlanrei);
        setLandingAboutMeData(data.data.data.page.moreAboutMe);
        setOfferData(data.data.data.page.offer);
    }

    return (
        <div>
          {offerData !== undefined ? (
            <>
              <Seo title="Olan Rei" description={'Olan Rei (Zdravlje) vilinski je simbol koji obuhvaća cjelokupni proces iscjeljivanja svih nivoa tijela – fizički, mentalno i duhovno. Olan Rei mjesto je gdje svojim dolaskom sebi dozvoljavate postizanje harmonije uma, duha i tijela.'} link='https://olanrei.com/'/>
              <MainSlider data={mainSliderData}/>
              <TextImageComponent heading={aboutOlanReiData.naslov1} text={aboutOlanReiData.opis1} sourceUrl={aboutOlanReiData.slika1.sourceUrl}/>
              <LandginOffer data={offerData}/>
              <LandginAboutMe sourceUrl={landingAboutMeData.slika.sourceUrl} header={landingAboutMeData.naslov} text={landingAboutMeData.opis} imageOutlineClass={"landing-more-about-me-image"} imageContainerClass={'landing-more-about-me-image-container-margin'}/>  
            </>
          ) : null}
           </div>
    )
}

export default Landing;