import React, { useEffect, useState } from 'react';
import './index.css';
import Shade from '../../images/Oval-sjena.png';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

const MainSlider = (data) => {

    const [baner, setBaner] = useState({baner: {slika: ''}, index: 0})
    const [newData, setNewData] = useState(0);
    const [overlayBaner, setOverlayBaner] = useState({ baner: {slika: ''}, class: ''});
    const [poslovica, setPoslovica] = useState({data: ''});
    const [sliderGoForward, setSliderGoForward] = useState();

    useEffect(() => {
        if(data.data.rotator[1] === undefined) return;
        if(baner.index === data.data.rotator.length - 1){
            setBaner({baner: data.data.rotator[0], index: 0});
        }else{
            setBaner({baner: data.data.rotator[baner.index + 1], index: baner.index + 1});
        }
        setOverlayBaner({...overlayBaner, class: 'fade-out-animation'});
        setPoslovica({...poslovica, class: 'poslovica-fade-out'});
        setTimeout(() => {
            if(baner.index === data.data.rotator.length - 1){
                setOverlayBaner({baner: data.data.rotator[0], class: ''})
                // setBaner({baner: data.data.rotator[0], index: 0});
                setPoslovica({data: data.data.rotator[0].poslovica, class: ''});
            }else{
                setPoslovica({data: data.data.rotator[baner.index + 1].poslovica, class: ''});
                // setBaner({baner: data.data.rotator[baner.index + 1], index: baner.index + 1});
                setOverlayBaner({baner: data.data.rotator[baner.index + 1], class: ''});   
            }
        }, 1600);
    }, [sliderGoForward])

    useEffect(() => {
        let timeout = setTimeout(() => {
            setSliderGoForward(Math.random());
            setNewData(Math.random());
        }, 17000)

        return () => {
            clearTimeout(timeout);
        }
    }, [newData])

    const slideForward = () => {
        setNewData(Math.random());
        setSliderGoForward(Math.random());
    }

    const slideBackward = () => {
        if(data.data.rotator[1] === undefined) return;
        setNewData(Math.random());
        if(baner.index === 0){
            setBaner({baner: data.data.rotator[data.data.rotator.length - 1], index: data.data.rotator.length - 1});
        }else{
            setBaner({baner: data.data.rotator[baner.index - 1], index: baner.index - 1});
        }
        setOverlayBaner({...overlayBaner, class: 'fade-out-animation'});
        setPoslovica({...poslovica, class: 'poslovica-fade-out'});
        setTimeout(() => {
            if(baner.index === 0){
                setOverlayBaner({baner: data.data.rotator[data.data.rotator.length - 1], class: ''})
                // setBaner({baner: data.data.rotator[0], index: 0});
                setPoslovica({data: data.data.rotator[data.data.rotator.length - 1].poslovica, class: ''});
            }else{
                setPoslovica({data: data.data.rotator[baner.index - 1].poslovica, class: ''});
                // setBaner({baner: data.data.rotator[baner.index + 1], index: baner.index + 1});
                setOverlayBaner({baner: data.data.rotator[baner.index - 1], class: ''});   
            }
        }, 1600);
    }

    

    return (
        <div className="main-rotator-outher-container">
            <div className="main-rotator-container">
                {data.data.rotator[1] ? (
                    <>
                        <img className={overlayBaner.class} style={{position: "absolute"}} src={overlayBaner.baner.slika.sourceUrl} alt="err"/>
                        <img className={`overlay-baner`} src={baner.baner.slika.sourceUrl} alt="err"/>
                    </>
                ): null}
                <div className="main-rotator-overlay">
                    <div className={`main-rotator-poslovica d-xs-none d-sm-none d-md-block ${poslovica.class}`}>
                        <p dangerouslySetInnerHTML={{ __html: poslovica.data.tekst }}></p>
                        {poslovica.naslov !== null ? (
                            <p className="main-rotator-poslovica-autor">{poslovica.data.naslov}</p>
                        ): null}
                        <div className="main-rotator-navigation main-rotator-navigation-display">
                            <ArrowBackIosIcon onClick={e => slideBackward()}/>
                            <ArrowForwardIosIcon  onClick={e => slideForward()}/>
                        </div>
                    </div>
                    <div className={`main-rotator-navigation d-md-none d-sm-flex d-xs-flex ${poslovica.class}`}>
                            <ArrowBackIosIcon onClick={e => slideBackward()}/>
                            <ArrowForwardIosIcon  onClick={e => slideForward()}/>
                    </div>
                </div>
            </div>
            <div className="main-rotator-container main-rotator-bottom-shadow-container">
                <img src={Shade} alt="err"/>
            </div>
        </div>
    )
}

export default MainSlider;